/* eslint-disable no-plusplus */
import React from "react";

export function regexPhoneNumber(str) {
	const regex = /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g;
	return regex.test(str.trim().toLowerCase());
}

export function regexMail(str) {
	/* eslint-disable-next-line */
	return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(str.trim().toLowerCase());
}

export function generateRandomCode(length = 5) {
	let code = "";
	let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
	for (let i = 0; i < length; i++) {
		code += possible.charAt(Math.floor(Math.random() * possible.length));
	}
	return code;
}

export function slugify(text) {
	return text
		.toString()
		.toLowerCase()
		.replace(/\s+/g, "-") // Replace spaces with -
		.replace(/[^\w-]+/g, "") // Remove all non-word chars
		.replace(/--+/g, "-") // Replace multiple - with single -
		.replace(/^-+/, "") // Trim - from start of text
		.replace(/-+$/, ""); // Trim - from
}

export function generateNameCode(text) {
	return `${slugify(text)}-${generateRandomCode()}`;
}
