/* eslint-disable no-extra-boolean-cast */
/* eslint-disable operator-assignment */
/* eslint-disable prefer-destructuring */
import { FormControl, InputLabel, MenuItem, Select, Switch } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDDatePicker from "components/Basics/MDDatePicker";
import MDInput from "components/Basics/MDInput";
import MuiPhoneNumber from "mui-phone-number";
import lod_ from "lodash";
import i18n from "i18next";
import MDTypography from "components/Basics/MDTypography";
import { useEffect, useState } from "react";
/**
 * Specific component for delay input
 */
const DelayRow = ({ label, value, onChange }) => {
	const [days, setDays] = useState(Math.floor(value / (24 * 3600 * 1000)));
	const [hours, setHours] = useState(Math.floor((value % (24 * 3600 * 1000)) / (3600 * 1000)));
	const [minutes, setMinutes] = useState(Math.floor((value % (3600 * 1000)) / (60 * 1000)));
	const [seconds, setSeconds] = useState(Math.floor((value % (60 * 1000)) / 1000));

	useEffect(() => {
		let newValue =
			days * 24 * 3600 * 1000 + hours * 3600 * 1000 + minutes * 60 * 1000 + seconds * 1000;
		onChange(newValue);
	}, [days, hours, minutes, seconds]);

	return (
		<MDBox>
			<MDBox mr={1}>
				<MDTypography variant="body1" fontSize="small">
					{label}
				</MDTypography>
			</MDBox>
			<MDBox mt={1} display="flex" alignItems="center">
				<MDBox mr={1} flex={1}>
					<MDInput
						type="number"
						className="dialogInput"
						label={i18n.t("SETTINGS.CHARTS.DATE.day")}
						value={days}
						onChange={e => setDays(e.target.value)}
						fullWidth
					/>
				</MDBox>
				<MDBox mr={1} flex={1}>
					<MDInput
						type="number"
						className="dialogInput"
						label={i18n.t("SETTINGS.CHARTS.DATE.hour")}
						value={hours}
						onChange={e => setHours(e.target.value)}
						inputProps={{
							max: 23,
							min: 0
						}}
					/>
				</MDBox>
				<MDBox mr={1} flex={1}>
					<MDInput
						type="number"
						className="dialogInput"
						label={i18n.t("SETTINGS.CHARTS.DATE.minute")}
						value={minutes}
						onChange={e => setMinutes(e.target.value)}
						inputProps={{
							max: 59,
							min: 0
						}}
					/>
				</MDBox>
				<MDBox flex={1}>
					<MDInput
						type="number"
						className="dialogInput"
						label={i18n.t("SETTINGS.CHARTS.DATE.second")}
						value={seconds}
						onChange={e => setSeconds(e.target.value)}
						inputProps={{
							max: 59,
							min: 0
						}}
					/>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};
/**
 * Component for input
 */
const FormInput = ({ item, path, datas, handleChange }) => {
	let type = item.type;
	let label = item.label.fr;
	let codes = item.codes || [];

	let value = lod_.get(datas, path);

	const onChange = value => {
		handleChange(path, value);
	};

	switch (type) {
		case "boolean":
			return (
				<MDBox
					mt={1}
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					className="customSwitchContainer"
					onClick={e => {
						onChange(!Boolean(value));
					}}
				>
					<span>{label}</span>
					<MDBox>
						<Switch checked={Boolean(value)} />
					</MDBox>
				</MDBox>
			);
		case "phone":
			return (
				<MDBox mt={1}>
					<MuiPhoneNumber
						defaultCountry="fr"
						onChange={e => onChange(e)}
						type="tel"
						className="dialogInput"
						label={label}
						value={value}
						variant="outlined"
					/>
				</MDBox>
			);
		case "date":
		case "datetime":
			return (
				<MDBox mt={1}>
					<MDDatePicker
						type="date"
						label={label}
						value={value}
						onChange={e => onChange(e)}
						options={{
							time_24hr: true,
							dateFormat: "d M Y"
						}}
						input={{
							placeholder: `${label} `,
							style: { width: "100%" }
						}}
					/>
				</MDBox>
			);
		case "number":
			return (
				<MDBox mt={1}>
					<MDInput
						type="number"
						className="dialogInput"
						label={label}
						value={value}
						onChange={e => onChange(e.target.value)}
					/>
				</MDBox>
			);
		case "delay":
			return <DelayRow label={label} value={value} onChange={onChange} />;
		case "code":
			return (
				<FormControl fullWidth>
					<InputLabel id="select-label">{label}</InputLabel>
					<Select
						labelId="select-label"
						id="select"
						label={label}
						value=""
						onChange={e => {
							onChange(e.target.value);
						}}
						// name={child}
					>
						{codes.map((code, index) => (
							<MenuItem key={index} value={code.value}>
								{code.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			);
		default:
			return (
				<MDBox mt={1}>
					<MDInput
						type={type}
						className="dialogInput"
						label={label}
						value={value}
						onChange={e => onChange(e.target.value)}
					/>
				</MDBox>
			);
	}
};
/**
 * Component for create a form from a dictionary
 */
export function LittleForm({ object, metadatasSkeleton, handleChange, level = 0, path = null }) {
	if (object.items) {
		object = object.items;
	}

	let keys = Object.keys(object);

	return (
		<div
			style={{
				marginLeft: `${level * 1}rem`
			}}
		>
			{keys.map((key, index) => {
				let item = object[key];
				if (item.type === "level") {
					return (
						<div
							key={index}
							style={{
								border: "1px solid #ccc",
								padding: "1rem",
								marginBottom: "1rem",
								borderRadius: "5px"
							}}
						>
							<h3>{item.label.fr}</h3>
							<LittleForm
								object={item.items}
								level={level + 1}
								metadatasSkeleton={metadatasSkeleton}
								handleChange={handleChange}
								path={path ? `${path}.${key}` : key}
							/>
						</div>
					);
				} else {
					return (
						<FormInput
							key={index}
							item={item}
							datas={metadatasSkeleton}
							handleChange={handleChange}
							path={path ? `${path}.${key}` : key}
						/>
					);
				}
			})}
		</div>
	);
}
/**
 * Create a datas skeleton from a dictionary
 * @param {*} dictionary
 * @param {*} values
 * @returns
 */
export function createDictionarySkeleton(dictionary, values = {}) {
	if (dictionary.items) {
		dictionary = dictionary.items;
	}

	let metadatas = {};
	let keys = Object.keys(dictionary);

	keys.map(key => {
		let item = dictionary[key];
		if (item.type === "level") {
			metadatas[key] = createDictionarySkeleton(item.items, values);
		} else {
			let defaultValue = values[key] !== undefined ? values[key] : "";
			metadatas[key] = defaultValue;
		}
	});

	return metadatas;
}
