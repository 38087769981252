import { Menu, MenuItem, Divider, Icon } from "@mui/material";
import MDTypography from "components/Basics/MDTypography";

/* eslint-disable no-underscore-dangle */
const { useEffect, useState } = require("react");
const lod_ = require("lodash");

const HandleTextSelection = ({ handleAction }) => {
	const [selectedText, setSelectedText] = useState("");
	const [menu, setMenu] = useState({ open: false, position: { x: 0, y: 0 } });

	const _handleAction = action => {
		handleAction(action, selectedText);
		setMenu({ open: false });
	};

	useEffect(() => {
		function handleMouseUp(e) {
			const selection = window.getSelection();
			const text = selection?.toString();

			/* if (lod_.isEmpty(text)) {
				setMenu({ open: false });
				return;
			} */
			setSelectedText(text);

			setMenu({
				open: true,
				position: { left: e.x, top: e.y }
			});

			e.preventDefault();
			e.stopPropagation();
		}

		window.addEventListener("contextmenu", handleMouseUp);
		return () => {
			window.removeEventListener("contextmenu", handleMouseUp);
		};
	}, []);

	return (
		<Menu
			open={menu.open}
			onClose={() => setMenu({ open: false })}
			anchorReference="anchorPosition"
			anchorPosition={menu.position}
			anchorOrigin={{ vertical: "top", horizontal: "left" }}
		>
			<MenuItem disabled style={{ opacity: 1 }}>
				<MDTypography variant="caption">Actions de selection</MDTypography>
			</MenuItem>
			<MenuItem
				onClick={() => {
					_handleAction("add-label");
				}}
			>
				<Icon fontSize="medium">sell</Icon>&nbsp;Ajouter un label
			</MenuItem>
		</Menu>
	);
};

export default HandleTextSelection;
