/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Doughnut } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";

// DefaultDoughnutChart configurations
import configs from "components/Advanced/Charts/DoughnutCharts/DefaultDoughnutChart/configs";

import { Chart as ChartJS, registerables } from "chart.js";
import DownloadCSV from "components/Custom/DownloadCSV";

ChartJS.register(...registerables);

function DefaultDoughnutChart({ icon, title, description, height, chart }) {
	const { data, options } = configs(chart.labels || [], chart.datasets || {}, chart.cutout);

	const renderChart = (
		<MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
			<MDBox
				display="flex"
				justifyContent="space-between"
				px={description ? 1 : 0}
				pt={description ? 1 : 0}
			>
				<MDBox display="flex">
					{icon.component && (
						<MDBox
							width="4rem"
							height="4rem"
							bgColor={icon.color || "info"}
							variant="gradient"
							coloredShadow={icon.color || "info"}
							borderRadius="xl"
							display="flex"
							justifyContent="center"
							alignItems="center"
							color="white"
							mt={-5}
							mr={2}
						>
							<Icon fontSize="medium">{icon.component}</Icon>
						</MDBox>
					)}
					<MDBox mt={icon.component ? -2 : 0}>
						{title && <MDTypography variant="h6">{title}</MDTypography>}
						<MDBox mb={2}>
							<MDTypography component="div" variant="button" color="text">
								{description}
							</MDTypography>
						</MDBox>
					</MDBox>
				</MDBox>
				<MDBox mt={icon.component ? -2 : 0}>
					<DownloadCSV data={data} title={title} />
				</MDBox>
			</MDBox>
			{useMemo(
				() => (
					<MDBox height={height}>
						<Doughnut data={data} options={options} />
					</MDBox>
				),
				[chart, height]
			)}
		</MDBox>
	);

	return <Card>{renderChart}</Card>;
}

// Setting default values for the props of DefaultDoughnutChart
DefaultDoughnutChart.defaultProps = {
	icon: { color: "info", component: "" },
	title: "",
	description: "",
	height: "19.125rem"
};

export default DefaultDoughnutChart;
