/* eslint-disable import/no-import-module-exports */
/**
 *--------------------------------------------------
 *
 * OPERATIONS DOC :
 * https://jsonlogic.com/operations.html
 *
 *--------------------------------------------------
 */
/* STRING */
const COMP_STRING = [
	{
		code: "==",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.eq"
	},
	{
		code: "!=",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.ne"
	},
	{
		code: "in",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.in"
	}
];
/* NUMBER */
const COMP_NUMBER = [
	{
		code: "==",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.eq"
	},
	{
		code: "!=",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.ne"
	},
	{
		code: ">",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.gt"
	},
	{
		code: ">=",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.gte"
	},
	{
		code: "<",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.lt"
	},
	{
		code: "<=",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.lte"
	}
];
/* CODE */
const COMP_CODE = [
	{
		code: "==",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.eq"
	},
	{
		code: "!=",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.ne"
	}
];
/* DATE */
const COMP_DATE = [
	{
		code: ">",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.gt"
	},
	{
		code: "<",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.lt"
	}
];

export default {
	COMP_STRING,
	COMP_NUMBER,
	COMP_CODE,
	COMP_DATE
};
