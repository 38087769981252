import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const permissions = initialState;

const slice = createSlice({
	name: "permissions",
	initialState,
	reducers: {
		setPermissions: (state, action) => {
			return action.payload;
		},
		updatePermissions: (state, action) => {
			state.permissions = [...action.payload];
			return state;
		}
	}
});

export const { setPermissions, updatePermissions } = slice.actions;

export default slice.reducer;
