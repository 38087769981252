/* eslint-disable no-empty */
/* eslint-disable prefer-destructuring */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const filters = initialState;

const slice = createSlice({
	name: "filters",
	initialState,
	reducers: {
		initFilter: (state, { payload }) => {
			let { assistantID } = payload;
			return JSON.parse(localStorage.getItem(`filters_${assistantID}`)) || {};
		},
		setFilter: (state, { payload }) => {
			// Set state in store
			let { assistantID, page, attribute, front, back, values, type, code, mode } = payload;

			state[assistantID] = state[assistantID] || {};
			state[assistantID][page] = state[assistantID][page] || {};
			state[assistantID][page][attribute] = {
				attribute,
				front,
				back,
				values,
				type,
				code,
				mode
			};

			localStorage.setItem(`filters_${assistantID}`, JSON.stringify(state));
		},
		deleteFilter: (state, { payload }) => {
			let { assistantID, page, attribute } = payload;
			try {
				delete state[assistantID][page][attribute];
				let objectInStorage = JSON.parse(localStorage.getItem(`filters_${assistantID}`));
				delete objectInStorage[assistantID][page][attribute];
				localStorage.setItem(`filters_${assistantID}`, JSON.stringify(objectInStorage));
			} catch (e) {}
		},
		resetFilters: (state, action) => {
			return initialState;
		}
	}
});

export const selectFilters = state => state.filters;

export const { initFilter, setFilter, deleteFilter, resetFilters } = slice.actions;

export default slice.reducer;
