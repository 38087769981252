import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const user = initialState;

const slice = createSlice({
	name: "user",
	initialState,
	reducers: {
		login: (state, action) => {
			return action.payload;
		}
	}
});

export const { login } = slice.actions;

export default slice.reducer;
