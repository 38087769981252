/* eslint-disable dot-notation */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import lod_ from "lodash";
// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import ListOptions from "components/Custom/ListOptions";
import { useState } from "react";
import { t } from "i18next";

function RankingCard({ chart, data, dictionary, count, percentage, handleEditChart, resizeChart }) {
	const [dictionaryChart, setDictionaryChart] = useState(
		dictionary[chart.request.collection] ?? {}
	);

	const {
		editMode = false,
		display: { icon, title }
	} = chart;

	const round = nb => {
		if (typeof nb === "number") {
			if (nb) {
				return Math.round(nb * 100) / 100;
			}
		}
		return nb;
	};

	const target = data["_id"];

	const getLabel = path => {
		if (!path) return "";
		let fullPath = path?.replaceAll(".", ".items.");
		let item = lod_.get(dictionaryChart.items, fullPath);
		if (item) {
			return item.label[Object.keys(item.label)[0]];
		}
		return path;
	};

	return (
		<Card>
			<MDBox display="flex" flexDirection="column" justifyContent="space-between" pt={1} px={2}>
				<MDBox
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="space-bewteen"
				>
					<MDBox
						flex="1"
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="start"
					>
						{icon?.component && (
							<MDBox
								bgColor={icon?.color || "info"}
								variant="gradient"
								coloredShadow={icon?.color || "info"}
								borderRadius="xl"
								display="flex"
								justifyContent="center"
								alignItems="center"
								width="3rem"
								height="3rem"
								color="white"
								mr={2}
							>
								<Icon fontSize="small" color="inherit">
									{icon?.component}
								</Icon>
							</MDBox>
						)}
						<MDBox display="flex" flexDirection="column">
							<MDTypography variant="h6">
								{title?.trim() !== "" ? title : t("CHARTS.RANKING.ranking")}
							</MDTypography>
							<MDTypography variant="button" color="text">
								{target}
							</MDTypography>
						</MDBox>
					</MDBox>

					{!editMode && (
						<MDBox>
							<ListOptions
								chart={chart}
								/* options={{
									edit: { active: true, action: handleEditChart },
									resize: {
										active: true,
										action: size => {
											resizeChart(size);
										}
									}
								}} */
								options={chart.options}
							/>
						</MDBox>
					)}
				</MDBox>

				{/* Content */}
				{target && (
					<MDBox
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="space-between"
						mt={3}
					>
						<MDBox display="flex" flexDirection="row" alignItems="end" justifyContent="start">
							<MDTypography sx={{ mb: -0.5 }} variant="h2">
								{data.rank}
							</MDTypography>
							<MDTypography sx={{ ml: 0.5 }} variant="h6" fontSize="medium">
								{`/ ${data.totalRank}`}
							</MDTypography>
						</MDBox>
						<MDBox>
							<MDTypography variant="h4">{count}</MDTypography>
						</MDBox>
					</MDBox>
				)}
			</MDBox>
			{target && (
				<>
					<Divider />
					<MDBox pb={2} px={2}>
						<MDBox
							display="flex"
							alignItems="center"
							justifyContent="space-between"
							flexDirection="row"
						>
							{/* Min */}
							{chart.display.extra?.min?.active && (
								<MDBox flex="1">
									<MDTypography variant="body2" fontWeight="bold">
										{t("CHARTS.RANKING.min")}
									</MDTypography>
									<MDTypography variant="body2">{round(data.min)}</MDTypography>
								</MDBox>
							)}
							{/* Max */}
							{chart.display.extra?.max?.active && (
								<MDBox flex="1">
									<MDTypography variant="body2" fontWeight="bold">
										{t("CHARTS.RANKING.max")}
									</MDTypography>
									<MDTypography variant="body2">{round(data.max)}</MDTypography>
								</MDBox>
							)}
							{/* Moyenne */}
							{chart.display.extra?.global?.active && (
								<MDBox flex="1">
									<MDTypography variant="body2" fontWeight="bold">
										{t("CHARTS.RANKING.global")}
									</MDTypography>
									<MDTypography variant="body2">{round(data.global)}</MDTypography>
								</MDBox>
							)}
							{/* Zone */}
							{data.zone && chart.request.reference?.zone && (
								<MDBox flex="1">
									<MDTypography variant="body2" fontWeight="bold">
										{getLabel(chart.request.reference?.zone)}
									</MDTypography>
									<MDTypography variant="body2">{round(data.zone.total)}</MDTypography>
								</MDBox>
							)}
						</MDBox>

						{percentage && (
							<MDTypography component="p" variant="button" color="text" display="flex">
								<MDTypography
									component="span"
									variant="button"
									fontWeight="bold"
									color={percentage.color}
								>
									{percentage.amount}
								</MDTypography>
								&nbsp;{percentage.label}
							</MDTypography>
						)}
					</MDBox>
				</>
			)}
			{!target && (
				<MDBox p={2}>
					<MDTypography variant="button" fontSize="medium">
						{t("CHARTS.RANKING.chooseElement")}&nbsp;
						<span style={{ fontWeight: "bold" }}>{getLabel(chart.request?.reference?.target)}</span>
						&nbsp;{t("CHARTS.RANKING.displayRanking")}
					</MDTypography>
				</MDBox>
			)}
		</Card>
	);
}

export default RankingCard;
