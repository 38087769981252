import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from "@mui/material";
import MDButton from "components/Basics/MDButton";

export default function ConfirmDialog({ open, handleClose, handleConfirm, title, content }) {
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">{content}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="dark" onClick={handleClose}>
					Annuler
				</MDButton>
				<MDButton variant="contained" color="dark" onClick={handleConfirm}>
					Confirmer
				</MDButton>
			</DialogActions>
		</Dialog>
	);
}
